export default {
  data() {
    return {
      tourExchange: {
        loading: false,
        details: {},
        list: [],
        listLoadMore: [],
        meta: {},
        filters: {
          id: "",
          name: "",
          users: {
            list: [],
            selected: [],
          },
          status: {
            list: [
              {
                label: "Aberto",
                value: "Aberto",
              },
              {
                label: "Ganho",
                value: "Ganho",
              },
              {
                label: "Perdido",
                value: "Perdido",
              },
            ],
            selected: [],
          },
          period: {
            selected: [],
          },
          order: "desc",
          orderBy: "created",
        },
        pagination: {
          page: 1,
          perPage: 20,
          total: 0,
          complete_list_total: 0,
          totalPages: 0,
        },
      },
      tourExchangeTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Moeda",
          dataIndex: "currency",
          key: "currency",
          class: "currency",
          width: 100,
          sorter: true,
        },
        {
          title: "Câmbio",
          dataIndex: "exchange",
          key: "exchange",
          class: "exchange",
          width: 100,
          sorter: true,
        },
        {
          title: "Valor",
          key: "value",
          width: 120,
          sorter: true,
          scopedSlots: { customRender: "value" },
        },
        {
          title: "Usuário",
          width: 150,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "Última atualização",
          width: 250,
          scopedSlots: { customRender: "last_update" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 90,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      currency: [
        {
          label: "R$",
          value: "brl",
        },
        {
          label: "U$",
          value: "usd",
        },
        {
          label: "€",
          value: "eur",
        },
      ],
    };
  },

  methods: {
    changeTourExchangePage(current) {
      this.tourExchange.pagination.page = current;
      this.getTourExchanges();
    },
    tourExchangeTableChange(pagination, filters, sorter) {
      console.log(filters, pagination);
      this.tourExchange.filters.order =
        sorter.order != undefined ? sorter.order : "desc";
      this.tourExchange.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getTourExchanges();
    },
    tourExchangeChangePageSize(current, pageSize) {
      this.tourExchange.pagination.page = current;
      this.tourExchange.pagination.perPage = pageSize;
      this.getTourExchanges();
    },
    tourExchangeFilters() {
      let filters = "";

      if (this.tourExchange.filters.status.selected.length > 0)
        filters += `&status=${this.tourExchange.filters.status.selected}`;

      if (this.tourExchange.filters.period.selected.length > 0)
        filters += `&period=${this.tourExchange.filters.period.selected[0]}|${this.tourExchange.filters.period.selected[1]}`;

      if (this.tourExchange.filters.id)
        filters += `&id=${this.tourExchange.filters.id}`;

      filters += `&order=${this.tourExchange.filters.order}&order-by=${this.tourExchange.filters.orderBy}`;

      return filters;
    },

    async getTourExchangeById(id) {
      try {
        const response = await this.$http.get(
          `/tourism-exchange/details?id=${id}`
        );
        this.tourExchange.details = response.data;
        return response.data
      } catch (error) {
        console.log(error);
        this.$message.error("Houve algum erro ao carregar os dados.");
        this.tourExchange.details = {};
      }
    },
    async getTourExchanges(samePage) {
      this.tourExchange.loading = true;
      try {
        const { data } = await this.$http.get(
          `/tourism-exchange/list?page=${
            this.tourExchange.pagination.page
          }&per_page=${
            this.tourExchange.pagination.perPage
          }${this.tourExchangeFilters()}`
        );

        this.tourExchange.list = data.data;
        this.tourExchange.pagination.page = data.meta.next_page;
        this.tourExchange.pagination.total = data.meta.total;

        if (!samePage) {
          this.tourExchange.pagination.page = 1;
        }
      } catch (e) {
        this.tourExchange.list = [];
        this.tourExchange.pagination.page = 0;
        this.tourExchange.pagination.total = 0;
        e;
      } finally {
        this.tourExchange.loading = false;
      }
    },
  },
};
