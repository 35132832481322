<template>
  <div>
    <aForm @submit.prevent="onSubmitForm" :form="form">
      <aRow :gutter="20">
        <aCol :span="24">
          <HayaSelectField
            label="Moeda (De)"
            fieldKey="currency"
            :list="currenciesList"
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
          />
        </aCol>

        <aCol :span="24">
          <HayaSelectField
            label="Câmbio (Para)"
            fieldKey="exchange"
            :list="currenciesList"
            :required="true"
            :form="form"
            :allowClear="true"
            :showSearch="true"
          />
        </aCol>

        <aCol v-if="form.getFieldValue('exchange')" :span="24">
          <HayaValueField
            label="Valor do câmbio"
            placeholder="Ex: 5,0001..."
            fieldKey="value"
            :vCurrency="vCurrency"
            :required="true"
            :form="form"
            :preIcon="true"
          />
        </aCol>

        <aCol
          v-if="form.getFieldValue('currency')"
          class="a-center mb-20 f18"
          :span="24"
        >
          <b
            >1 {{ form.getFieldValue("currency") }} =
            {{ form.getFieldValue("value") }}
            {{ form.getFieldValue("exchange") }}
          </b>
        </aCol>

        <aCol :span="24">
          <a-button
            class="page-button"
            type="primary"
            html-type="submit"
            block
            :loading="loading"
            :disabled="loading"
          >
            {{ type === "edit" ? "Atualizar" : "Cadastrar" }}
          </a-button>
        </aCol>
      </aRow>
    </aForm>
  </div>
</template>

<script>
import HayaSelectField from "@/components/general/fields/HayaSelectField.vue";
import HayaValueField from "@/components/general/fields/HayaValueField.vue";
import tourismExchangeMixins from "../mixins/tourismExchangeMixins";

export default {
  name: "ExchangeForm",
  props: {
    type: String,
    theExchange: Object,
  },
  components: { HayaValueField, HayaSelectField },
  mixins: [tourismExchangeMixins],
  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      currenciesList: [],
    
    };
  },
  mounted() {
    this.$http
      .get(
        `/category-entry/list?page=1&per_page=500&category_id=36&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.currenciesList = data.data.map(({ name, meta }) => {
          return {
            label: `${name}`,
            value: meta.currency_code,
          };
        });
      });

    if (this.type === "edit")
      try {
        this.getTourExchangeById(this.theExchange.id).then((data) => {
          console.log(data);

          this.form.setFieldsValue({
            currency: data.currency,
            exchange: data.exchange,
          });

          setTimeout(() => {
            this.form.setFieldsValue({
              value: data.value.replace(".", ","),
            });
          }, 10);
        });
      } catch (e) {
        console.log(e);
      }
  },
  methods: {
    createExchange(values) {
      values.user_id = this.$store.state.userData.id;

      this.$http
        .post("/tourism-exchange/create", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: data.id,
            module: "tourism-exchange",
            action: "create",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} cadastrou um novo câmbio ID ${data.id}. Valor informado: 1 ${values.currency} = ${values.value} ${values.exchange}`,
          });
          this.loading = false;
          this.$emit("onCreateExchange");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loading = false;
        });
    },
    editExchange(values) {
      values.id = this.theExchange.id;
      values.user_id = this.theExchange.user.id;

      this.$http
        .post("/tourism-exchange/update", values)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$http.post("/log/create", {
            user_id: this.$store.state.userData.id,
            module_id: this.theExchange.id,
            module: "tourism-exchange",
            action: "update",
            description: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou o câmbio ID ${this.theExchange.id}. Alteração: 1 ${values.currency} = ${values.value} ${values.exchange}`,
          });
          this.loading = false;
          this.$emit("onEditExchange");
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
          this.loading = false;
        });
    },
    onSubmitForm(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(err, values);

        values.status = "Ativo";
        values.value = values.value.replace(",", ".");

        if (!err && this.type === "create") this.createExchange(values);
        if (!err && this.type === "edit") this.editExchange(values);
      });
    },
  },
};
</script>

<style lang="sass" scoped></style>

<style lang="scss" scoped></style>
